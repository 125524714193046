const development = {
    b3bnbAccountCookieApiV1Config: {
        URL: "http://ac.localhost.com:8002",
    },

    inboxBusinessReportBrazilRestApiV1Config: {
        URL: "http://localhost:8036/v1",
    },

    govNativeSaleNFCeReportBrazilRestApiV1Config: {
        URL: "http://localhost:8038/v1",
    },

    b3bnbAccountApiV1Config: {
        URL_GRAPHQL: "http://localhost:8000/v1/graphql"
    },

    localizationBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8001/v1/graphql"
    },

    b3bnbCompanyBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8010/v1/graphql"
    },

    inboxBusinessSecurityBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8012/v1/graphql"
    },

    inboxBusinessSettingBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8003/v1/graphql"
    },

    inboxBusinessFinancialBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8017/v1/graphql"
    },

    inboxBusinessClientBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8019/v1/graphql"
    },

    inboxBusinessTransportBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8020/v1/graphql"
    },

    inboxBusinessSupplierBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8021/v1/graphql"
    },

    inboxBusinessProductBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8013/v1/graphql"
    },

    inboxBusinessServiceBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8014/v1/graphql"
    },

    inboxBusinessInvoicePayableBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8015/v1/graphql"
    },

    inboxBusinessInvoiceReceivableBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8016/v1/graphql"
    },

    inboxBusinessServiceSaleBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8023/v1/graphql"
    },

    inboxBusinessProductSaleBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8022/v1/graphql"
    },

    inboxBusinessContractBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8018/v1/graphql"
    },

    inboxBusinessPaymentMethodBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8024/v1/graphql"
    },

    inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8025/v1/graphql"
    },

    govNativeNFCeTransactionBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8009/v1/graphql"
    },

    govNativeIdentificationDocumentBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8035/v1/graphql"
    },

    b3bnbAccountPlanInvoiceApiV1Config: {
        URL_GRAPHQL: "http://localhost:8028/v1/graphql"
    },

    b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config: {
        URL_GRAPHQL: "http://localhost:8029/v1/graphql"
    },

    b3bnbAccountPlanInvoiceDepositApiV1Config: {
        URL_GRAPHQL: "http://localhost:8032/v1/graphql"
    },

    govNativeNFeTypeBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8006/v1/graphql"
    },

    digitalCertificateApiV1Config: {
        URL_GRAPHQL: "http://localhost:8004/v1/graphql",
        URL_REST: "http://localhost:8004"
    },

    inboxBusinessSearchBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8007/v1/graphql"
    },

    b3bnbAccountPlanApiV1Config: {
        URL_GRAPHQL: "http://localhost:8008/v1/graphql"
    },

    govNativeMonthlyAccountingClosingApiV1Config: {
        URL_GRAPHQL: "http://localhost:8037/v1/graphql"
    },

    inboxBusinessProductQuotationBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8042/v1/graphql"
    },

    inboxBusinessProductStockEntryBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8043/v1/graphql"
    },

    inboxBusinessCollaboratorAccountBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8044/v1/graphql"
    },

    inboxBusinessPosBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8045/v1/graphql"
    },

    inboxBusinessCustomerBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8019/v1/graphql"
    },

    inboxBusinessRestaurantBrazilApiV1Config: {
        URL_GRAPHQL: "http://localhost:8046/v1/graphql"
    },

    inboxBusinessRestaurantReportBrazilRestApiV1Config: {
        URL: "http://localhost:8049"
    },

    inboxBusinessRestaurantOnlineOrderApiV1Config: {
        URL_GRAPHQL: "http://localhost:8050"
    },
}

const production = {
    b3bnbAccountCookieApiV1Config: {
        URL: "https://accountcookie.b3bnb.com",
    },

    inboxBusinessReportBrazilRestApiV1Config: {
        URL: "https://apis.b3bnb.com/inbox-business-report-brazil-rest-api/v1",
    },

    govNativeSaleNFCeReportBrazilRestApiV1Config: {
        URL: "https://apis.b3bnb.com/gov-native-sale-nfce-report-brazil-rest-api/v1",
    },

    b3bnbAccountApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-account-graphql-api/v1/graphql"
    },

    localizationBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/localization-brazil-graphql-api/v1/graphql"
    },

    b3bnbCompanyBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-company-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessSecurityBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-security-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessSettingBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-setting-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessFinancialBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-financial-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessClientBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-client-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessTransportBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-transport-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessSupplierBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-supplier-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessServiceBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-service-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessInvoicePayableBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-invoice-payable-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessInvoiceReceivableBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-invoice-receivable-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessServiceSaleBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-service-sale-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductSaleBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-sale-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessContractBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com//v1/graphql"
    },

    inboxBusinessPaymentMethodBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-payment-method-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-sale-nfce-transaction-brazil-graphql-api/v1/graphql"
    },

    govNativeNFCeTransactionBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/gov-native-nfce-transaction-brazil-graphql-api/v1/graphql"
    },

    govNativeIdentificationDocumentBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/gov-native-identification-document-brazil-graphql-api/v1/graphql"
    },

    b3bnbAccountPlanInvoiceApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-account-plan-invoice-graphql-api/v1/graphql"
    },

    b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-account-plan-invoice-cielo-super-link-graphql-api/v1/graphql"
    },

    b3bnbAccountPlanInvoiceDepositApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-account-plan-invoice-deposit-graphql-api/v1/graphql"
    },

    govNativeNFeTypeBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/gov-native-nfe-type-brazil-graphql-api/v1/graphql"
    },

    digitalCertificateApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/digital-certificate-brazil-graphql-api/v1/graphql",
        URL_REST: "https://apis.b3bnb.com/digital-certificate-brazil-graphql-api"
    },

    inboxBusinessSearchBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-search-brazil-graphql-api/v1/graphql"
    },

    b3bnbAccountPlanApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/b3bnb-account-plan-graphql-api/v1/graphql"
    },

    govNativeMonthlyAccountingClosingApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/gov-native-monthly-accounting-closing-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductQuotationBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-quotation-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessProductStockEntryBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-product-stock-entry-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessCollaboratorAccountBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-collaborator-account-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessPosBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-pos-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessCustomerBrazilApiV1Config: {
        URL_GRAPHQL: "https://apis.b3bnb.com/inbox-business-customer-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessRestaurantBrazilApiV1Config: {
        URL_GRAPHQL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-brazil-graphql-api/v1/graphql"
    },

    inboxBusinessRestaurantReportBrazilRestApiV1Config: {
        URL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-report-brazil-rest-api/v1"
    },

    inboxBusinessRestaurantOnlineOrderApiV1Config: {
        URL_GRAPHQL: "https://proxy-restaurant-api.b3bnb.com/inbox-business-restaurant-online-order-brazil-graphql-api/v1/graphql"
    },
}

export {
    development,
    production
}
