import { production as configApi } from "./config"
import nodeFetch from "node-fetch"

async function getToken() {
    const options = {
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
            name: "token"
        })
    }

    return await (await nodeFetch(`${configApi.b3bnbAccountCookieApiV1Config.URL}/v1/getCookie`, options)).json()
}

export {
    getToken
}