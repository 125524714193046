import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import "bulma-modal-fx/dist/css/modal-fx.min.css"
import { modal } from "../../commons/modal"

function MenuBurger() {
    const historySession = useSelector(state => state.session.history)
    const accountSession = useSelector(state => state.session.account)
    const companySession = useSelector(state => state.session.company)

    useEffect(() => {
        init()
    }, [companySession])

    function init() {
        if (companySession) {

        }
    }

    function menuBigOpen() {
        menuBigClose()
        modal.open("modalMenuBig")
    }

    function menuBigClose() {
        modal.close("modalMenuBurgerBig")
    }

    function handleLogout(_) {
        menuBigClose()
        window.location.href = "https://accounts.b3bnb.com/logout?service=inbox-business-brazil"
    }

    function handleOpenPos() {
        const url = companySession ? "https://pos.b3bnb.com/login/" + companySession.ID : "https://pos.b3bnb.com/login/"
        window.open(url, '_blank')
    }


    return (
        <>
            <div id="modalMenuBurgerBig" className="modal modal-full-screen modal-fx-fadeInScale">
                <div className="modal-content modal-card">
                    <header className="modal-card-head" style={{ backgroundColor: "#fff", borderBottom: "unset" }}>
                        <div style={{ fontSize: "18px", backgroundColor: "#265D26", padding: "2px 5px", color: "#fff" }}>B3BNB</div>
                        <div style={{ marginLeft: "5px", padding: "5px", color: "#000" }}>Inbox</div>
                        <p className="modal-card-title" style={{ marginLeft: "10px" }}></p>
                        <button className="button is-small is-white" onClick={_ => menuBigClose()}>
                            <span className="icon is-small">
                                <i className="fas fa-times" style={{ fontSize: "20px" }}></i>
                            </span>
                        </button>
                    </header>
                    <section className="modal-card-body" style={{ paddingTop: "0px" }}>
                        <div className="columns">
                            <div className="column">
                                <div className="tile is-ancestor">
                                    <div className="tile is-vertical">
                                        <div className="tile">
                                            <div className="tile is-parent is-vertical">
                                                <article className="tile is-child notification is-primary" style={{ backgroundColor: "#e7f6ff", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>{accountSession.name}</strong>
                                                    </p>
                                                    <p className="subtitle">
                                                        {accountSession.email}
                                                    </p>
                                                    <p className="title" style={{ marginTop: "3px" }}>
                                                        <strong>{companySession ? companySession.socialName : "Empresa"}</strong>
                                                    </p>
                                                    <p className="subtitle">{companySession ? companySession.document : ""}</p>
                                                </article>
                                                {/* <article className="tile is-child notification is-warning" style={{ backgroundColor: "#ffeff3", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>{companySession ? companySession.socialName : "Empresa"}</strong>
                                                    </p>
                                                    <p className="subtitle">{companySession ? companySession.document : ""}</p>
                                                </article> */}
                                                {/* <article className="tile is-child notification is-warning" style={{ backgroundColor: "#fff8dd", color: "#000" }}>
                                                    <p className="title">
                                                        <strong>Suporte</strong>
                                                    </p>
                                                    <p className="subtitle">(81) 3033-5347</p>
                                                </article> */}
                                                {/* <article className="tile is-child notification" onClick={_ => menuBigOpen()} style={{ background: "#000" }}>
                                                    <p className="title">
                                                        <strong className="button-exit">MENU</strong>
                                                    </p>
                                                </article> */}
                                                <article className="tile is-child notification" onClick={_ => menuBigOpen()}>
                                                    <p className="title">
                                                        <strong>MENU</strong>
                                                    </p>
                                                </article>
                                                <article className="tile is-child notification" onClick={_ => handleOpenPos()}>
                                                    <p className="title">
                                                        <strong>PONTO DE VENDA</strong>
                                                    </p>
                                                </article>
                                                <article className="tile is-child notification" onClick={_ => handleLogout()}>
                                                    <p className="title">
                                                        <span>SAIR</span>
                                                        <span className="icon is-small" style={{ marginLeft: "20px" }}>
                                                            <i className="fas fa-arrow-right"></i>
                                                        </span>
                                                    </p>
                                                </article>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="columns">
                            <div className="column"></div>
                        </div>
                    </section>
                </div>
            </div>
        </>
    )
}

export {
    MenuBurger
}
