const PLAN_STATUS_ACTIVE = "1"
const PLAN_STATUS_ACTIVE_FREE = "6"
const PLAN_STATUS_BLOCKED = "2"
const PLAN_STATUS_CANCELED = "3"
const PLAN_STATUS_FREE_EXPIRED = "4"
const PLAN_STATUS_BLOCKED_OVERDUE_INVOICE = "5"

export {
    PLAN_STATUS_ACTIVE,
    PLAN_STATUS_ACTIVE_FREE,
    PLAN_STATUS_BLOCKED,
    PLAN_STATUS_CANCELED,
    PLAN_STATUS_FREE_EXPIRED,
    PLAN_STATUS_BLOCKED_OVERDUE_INVOICE
}
