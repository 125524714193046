class PreconditionError {
    constructor(message) {
        this.code = 1
        this.message = message
    }
}

class InternalServerError {
    constructor(message) {
        this.code = 2
        this.message = message
    }
}

export {
    PreconditionError,
    InternalServerError
}