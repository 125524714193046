import React from 'react'

function NavBarAccountPlanFreeExpired() {
    return (
        <nav className="navbar is-transparent" role="navigation" aria-label="dropdown navigation">
            <div className="navbar-brand">
                <a className="navbar-item">

                    <img src="/logo.png" alt="b3bnb inbox" />
                    <div className="product-name">Inbox</div>
                </a>
            </div>
        </nav>
    )
}

export {
    NavBarAccountPlanFreeExpired
}