import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { sessionHistoryAdd, sessionTokenAdd, sessionAccountAdd, sessionAccountPlanAdd, sessionLogin } from '../../store/ducks/session'
import { Controller } from './controller/controller'
import { getError } from '../../../domain/apis/graphql'
import { validate } from '../../commons/validate'
import {
  PLAN_STATUS_ACTIVE, PLAN_STATUS_ACTIVE_FREE,
  PLAN_STATUS_BLOCKED, PLAN_STATUS_FREE_EXPIRED,
  PLAN_STATUS_BLOCKED_OVERDUE_INVOICE
} from '../../../domain/constants/plan_status'
import { AppRender } from './AppRender'
import './style.css'

function App(props) {
  const dispatch = useDispatch()

  useEffect(() => {
    //dispatch(enableLoad())
    init()
  }, [dispatch])

  async function init() {
    let accountByTokenResponse = null
    let errorAccountByTokenResponse = null
    let account = null
    let accountPlanResponse = null
    let errorAccountPlanResponse = null
    let accountPlan = null
    let listCompaniesResponse = null
    let errorListCompaniesResponse = null
    let companiesBrazil = null

    const cookieToken = await Controller.getCookieToken()

    dispatch(sessionHistoryAdd(props.history))

    if (cookieToken && cookieToken.value !== "") {
      accountByTokenResponse = await Controller.getAccountByToken(cookieToken.value)
      errorAccountByTokenResponse = getError(accountByTokenResponse)

      if (!errorAccountByTokenResponse) {
        account = accountByTokenResponse.data.accountByToken.account
        dispatch(sessionTokenAdd(cookieToken.value))

        if (account) {
          accountPlanResponse = await Controller.geAccountPlanInboxBusinessBrazilByAccount(account.ID)
          errorAccountPlanResponse = getError(accountPlanResponse)

          if (!errorAccountPlanResponse) {
            accountPlan = accountPlanResponse.data.accountPlanInboxBusinessBrazilByAccount.accountPlan
            dispatch(sessionAccountAdd(account))

            if (accountPlan) {
              dispatch(sessionAccountPlanAdd(accountPlan))
              listCompaniesResponse = await Controller.listCompaniesBrazil(account.ID)
              errorListCompaniesResponse = getError(listCompaniesResponse)

              if (!errorListCompaniesResponse) {
                companiesBrazil = listCompaniesResponse.data.companiesBrazil.companiesBrazil
                if (validate.checkNotEmptyList(companiesBrazil)) {
                  dispatch(sessionLogin(cookieToken.value, account, accountPlan, companiesBrazil[0]))

                  if (accountPlan.planStatusID === PLAN_STATUS_ACTIVE
                    || accountPlan.planStatusID === PLAN_STATUS_ACTIVE_FREE) {
                    if (isRouteCreateFlow()) {
                      pageHome()
                    }
                  } else if (accountPlan.planStatusID === PLAN_STATUS_FREE_EXPIRED) {
                    accountPlanFreeExpiredPage()
                  } else if (accountPlan.planStatusID === PLAN_STATUS_BLOCKED_OVERDUE_INVOICE) {
                    accountPlanBlockedOverdueInvoicePage()
                  } else if (accountPlan.planStatusID === PLAN_STATUS_BLOCKED) {
                    accountPlanBlockedPage()
                  }
                } else {
                  pageCreateCompanyChoisePersonType()
                }
              } else {
                pageLogin()
              }
            } else {
              pageCreateAccountPlan()
            }
          } else {
            pageLogin()
          }
        } else {
          pageLogin()
        }
      } else {
        pageLogin()
      }
    } else {
      pageLogin()
    }
  }

  function pageLogin() {
    window.location.href = "https://accounts.b3bnb.com/login?service=inbox-business-brazil"
  }

  function pageCreateAccountPlan() {
    props.history.push("/flow/createAccountPlan")
  }

  function pageCreateCompanyChoisePersonType() {
    props.history.push("/flow/createCompanyChoisePersonType")
  }

  function pageHome() {
    props.history.push("/home")
  }

  function accountPlanFreeExpiredPage() {
    props.history.push("/accountPlanFreeExpired")
  }

  function accountPlanBlockedOverdueInvoicePage() {
    props.history.push("/accountPlanBlockedOverdueInvoice")
  }

  function accountPlanBlockedPage() {
    props.history.push("/accountPlanBlocked")
  }

  function isRouteCreateFlow() {
    const path = window.location.pathname
    const flows = ["/flow/createAccountPlan", "/flow/createCompany"]

    for (let i = 0; i < flows.length; i++) {
      if (path.includes(flows[i])) {
        return true
      }
    }

    return false
  }

  return (
    <AppRender props={props} />
  )
}

export { App }