const accountFunctionalitiesMenuPermissionQuery = `
query($accountID:ID!,$accountPlanID:ID!,$companyID:ID!){
    accountFunctionalitiesMenuPermission(accountID:$accountID,accountPlanID:$accountPlanID,companyID:$companyID){
      menus{
        ID,
        name,
        systemID,
        submenus{
          ID,
          name,
          functionalities{
            name,
            path
          }
        }
      },
      error{
        code,
        message
      }
  
    }
  }
`

export {
  accountFunctionalitiesMenuPermissionQuery
}