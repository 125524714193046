import rp from "request-promise"
import { InternalServerError, PreconditionError } from "./error"
import { production as configApi } from "./config"

function graphqlRequest(uri, body, variables) {
    body = {
        query: body
    }

    if (variables) {
        body.variables = variables
    }

    let options = {
        method: "POST",
        uri,
        body,
        json: true
    }

    return rp(options)
}

async function graphqlB3bnbAccountApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbAccountApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbAccountPlanApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlLocalizationBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.localizationBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbCompanyBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbCompanyBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessSecurityBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessSecurityBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessSettingBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessSettingBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessFinancialBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessFinancialBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessClientBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessClientBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessTransportBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessTransportBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessSupplierBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessSupplierBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessProductBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessServiceBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessServiceBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessInvoicePayableBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessInvoicePayableBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessInvoiceReceivableBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessInvoiceReceivableBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessServiceSaleBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessServiceSaleBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductSaleBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessProductSaleBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessContractBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessContractBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessPaymentMethodBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessPaymentMethodBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductSaleNFCeTransactionBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessProductSaleNFCeTransactionBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeNFCeTransactionBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.govNativeNFCeTransactionBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeIdentificationDocumentBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.govNativeIdentificationDocumentBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbAccountPlanInvoiceApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceCieloSuperLinkApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbAccountPlanInvoiceCieloSuperLinkApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlB3bnbAccountPlanInvoiceDepositApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.b3bnbAccountPlanInvoiceDepositApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlGovNativeNFeTypeBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.govNativeNFeTypeBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlDigitalCertificateApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.digitalCertificateApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessSearchBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessSearchBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        console.log(error)
        return getInternalServerError()
    }
}

async function graphqlGovNativeMonthlyAccountingClosingApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.govNativeMonthlyAccountingClosingApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        console.log(error)
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductQuotationBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessProductQuotationBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        console.log(error)
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessProductStockEntryBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessProductStockEntryBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessCollaboratorAccountBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessCollaboratorAccountBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessPosBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessPosBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessCustomerBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessCustomerBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessRestaurantBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessRestaurantBrazilApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

async function graphqlInboxBusinessRestaurantOnlineOrderBrazilApiV1(body, variables) {
    try {
        return await graphqlRequest(configApi.inboxBusinessRestaurantOnlineOrderApiV1Config.URL_GRAPHQL, body, variables)
    } catch (error) {
        return getInternalServerError()
    }
}

function getInternalServerError() {
    return new InternalServerError("Erro de comunicação")
}

function getError(obj) {
    //error graphql
    if (obj.errors) {
        return getInternalServerError()
        //erro network
    } else if (obj instanceof InternalServerError || obj instanceof PreconditionError) {
        return obj
        // error domain
    } else if (obj.data[Object.keys(obj.data)[0]].error) {
        return obj.data[Object.keys(obj.data)[0]].error
    }

    return null
}

export {
    getError,
    graphqlB3bnbAccountApiV1,
    graphqlB3bnbAccountPlanApiV1,
    graphqlLocalizationBrazilApiV1,
    graphqlB3bnbCompanyBrazilApiV1,
    graphqlInboxBusinessSecurityBrazilApiV1,
    graphqlInboxBusinessSettingBrazilApiV1,
    graphqlInboxBusinessFinancialBrazilApiV1,
    graphqlInboxBusinessClientBrazilApiV1,
    graphqlInboxBusinessTransportBrazilApiV1,
    graphqlInboxBusinessSupplierBrazilApiV1,
    graphqlInboxBusinessProductBrazilApiV1,
    graphqlInboxBusinessServiceBrazilApiV1,
    graphqlInboxBusinessInvoicePayableBrazilApiV1,
    graphqlInboxBusinessInvoiceReceivableBrazilApiV1,
    graphqlInboxBusinessServiceSaleBrazilApiV1,
    graphqlInboxBusinessProductSaleBrazilApiV1,
    graphqlInboxBusinessContractBrazilApiV1,
    graphqlInboxBusinessPaymentMethodBrazilApiV1,
    graphqlGovNativeNFeTypeBrazilApiV1,
    graphqlInboxBusinessProductSaleNFCeTransactionBrazilApiV1,
    graphqlGovNativeNFCeTransactionBrazilApiV1,
    graphqlGovNativeIdentificationDocumentBrazilApiV1,
    graphqlB3bnbAccountPlanInvoiceApiV1,
    graphqlB3bnbAccountPlanInvoiceCieloSuperLinkApiV1,
    graphqlB3bnbAccountPlanInvoiceDepositApiV1,
    graphqlDigitalCertificateApiV1,
    graphqlInboxBusinessSearchBrazilApiV1,
    graphqlGovNativeMonthlyAccountingClosingApiV1,
    graphqlInboxBusinessProductQuotationBrazilApiV1,
    graphqlInboxBusinessProductStockEntryBrazilApiV1,
    graphqlInboxBusinessCollaboratorAccountBrazilApiV1,
    graphqlInboxBusinessPosBrazilApiV1,
    graphqlInboxBusinessCustomerBrazilApiV1,
    graphqlInboxBusinessRestaurantBrazilApiV1,
    graphqlInboxBusinessRestaurantOnlineOrderBrazilApiV1
}
