function checkEmptyString(value) {
    return (value == null || value.trim() === "") ? true : false
}

function checkNotEmptyString(value) {
    return (value != null && value.trim() !== "") ? true : false
}

function checkEmptyList(list) {
    return !list || list.length === 0 ? true : false
}

function checkNotEmptyList(list) {
    return list && list.length > 0 ? true : false
}

function isInt(val) {
    return Number.isInteger(parseInt(val))
}

function checkStrToInt(str) {
    return /^\+?\d+$/.test(str);
}

function isEmail(email) {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

const validate = {
    checkEmptyString,
    checkNotEmptyString,
    checkEmptyList,
    checkNotEmptyList,
    isInt,
    checkStrToInt,
    isEmail
}

export {
    validate
}