import React, { Suspense, lazy } from "react"
import { Switch, Route } from "react-router-dom"

const CreateAccountPlanFlowPage = lazy(() => import("../pages/CreateAccountPlanFlowPage/CreateAccountPlanFlowPage"))
const CreateCompanyFlowPage = lazy(() => import("../pages/CreateCompanyFlowPage/CreateCompanyFlowPage"))
const HomePage = lazy(() => import("../pages/HomePage/HomePage"))
const NoMatchPage = lazy(() => import("../pages/NoMatchPage/NoMatchPage"))
const ConfigNFCeCSCPage = lazy(() => import("../pages/ConfigNFCeCSCPage/ConfigNFCeCSCPage"))
const ConfigNFCeSerialPage = lazy(() => import("../pages/ConfigNFCeSerialPage/ConfigNFCeSerialPage"))
const ConfigNFeSerialPage = lazy(() => import("../pages/ConfigNFeSerialPage/ConfigNFeSerialPage"))
const ConfigNFSeSerialPage = lazy(() => import("../pages/ConfigNFSeSerialPage/ConfigNFSeSerialPage"))
const DigitalCertificatePage = lazy(() => import("../pages/DigitalCertificatePage/DigitalCertificatePage"))
const CostCenterPage = lazy(() => import("../pages/CostCenterPage/CostCenterPage"))
const ProductCategoryPage = lazy(() => import("../pages/ProductCategoryPage/ProductCategoryPage"))
const ServiceCategoryPage = lazy(() => import("../pages/ServiceCategoryPage/ServiceCategoryPage"))
const InvoicePayableCategoryPage = lazy(() => import("../pages/InvoicePayableCategoryPage/InvoicePayableCategoryPage"))
const InvoicePayablePage = lazy(() => import("../pages/InvoicePayablePage/InvoicePayablePage"))
const ServicePage = lazy(() => import("../pages/ServicePage/ServicePage"))
const CreateServicePage = lazy(() => import("../pages/CreateServicePage/CreateServicePage"))
const UpdateServicePage = lazy(() => import("../pages/UpdateServicePage/UpdateServicePage"))
const CustomerPage = lazy(() => import("../pages/CustomerPage/CustomerPage"))
const CreateCustomerPage = lazy(() => import("../pages/CreateCustomerPage/CreateCustomerPage"))
const UpdateCustomerPage = lazy(() => import("../pages/UpdateCustomerPage/UpdateCustomerPage"))
const ServiceSalePage = lazy(() => import("../pages/ServiceSalePage/ServiceSalePage"))
const ContractPage = lazy(() => import("../pages/ContractPage/ContractPage"))
const CreateContractPage = lazy(() => import("../pages/CreateContractPage/CreateContractPage"))
const UpdateContractPage = lazy(() => import("../pages/UpdateContractPage/UpdateContractPage"))
const CreateServiceSalePage = lazy(() => import("../pages/CreateServiceSalePage/CreateServiceSalePage"))
const InvoiceReceivableCategoryPage = lazy(() => import("../pages/InvoiceReceivableCategoryPage/InvoiceReceivableCategoryPage"))
const InvoiceReceivablePage = lazy(() => import("../pages/InvoiceReceivablePage/InvoiceReceivablePage"))
const SupplierPage = lazy(() => import("../pages/SupplierPage/SupplierPage"))
const CreateSupplierPage = lazy(() => import("../pages/CreateSupplierPage/CreateSupplierPage"))
const UpdateSupplierPage = lazy(() => import("../pages/UpdateSupplierPage/UpdateSupplierPage"))
const TransportPage = lazy(() => import("../pages/TransportPage/TransportPage"))
const CreateTransportPage = lazy(() => import("../pages/CreateTransportPage/CreateTransportPage"))
const UpdateTransportPage = lazy(() => import("../pages/UpdateTransportPage/UpdateTransportPage"))
const ViewServiceSalePage = lazy(() => import("../pages/ViewServiceSalePage/ViewServiceSalePage"))
const UpdateServiceSalePage = lazy(() => import("../pages/UpdateServiceSalePage/UpdateServiceSalePage"))
const ProductPage = lazy(() => import("../pages/ProductPage/ProductPage"))
const CreateProductPage = lazy(() => import("../pages/CreateProductPage/CreateProductPage"))
const UpdateProductPage = lazy(() => import("../pages/UpdateProductPage/UpdateProductPage"))
const ProductSalePage = lazy(() => import("../pages/ProductSalePage/ProductSalePage"))
const CreateProductSalePage = lazy(() => import("../pages/CreateProductSalePage/CreateProductSalePage"))
const UpdateProductSalePage = lazy(() => import("../pages/UpdateProductSalePage/UpdateProductSalePage"))
const ViewProductSalePage = lazy(() => import("../pages/ViewProductSalePage/ViewProductSalePage"))
const VerifyIssueProductTaxInvoicePage = lazy(() => import("../pages/VerifyIssueProductTaxInvoicePage/VerifyIssueProductTaxInvoicePage"))
const IssueNFCePage = lazy(() => import("../pages/IssueNFCePage/IssueNFCePage"))
const CheckNFCeEmissionPage = lazy(() => import("../pages/CheckNFCeEmissionPage/CheckNFCeEmissionPage"))
const TaxConfigPage = lazy(() => import("../pages/TaxConfigPage/TaxConfigPage"))
const AccountPlanFreeExpiredPage = lazy(() => import("../pages/AccountPlanFreeExpiredPage/AccountPlanFreeExpiredPage"))
const AccountPlanBlockedOverdueInvoicePage = lazy(() => import("../pages/AccountPlanBlockedOverdueInvoicePage/AccountPlanBlockedOverdueInvoicePage"))
const AccountPlanBlockedPage = lazy(() => import("../pages/AccountPlanBlockedPage/AccountPlanBlockedPage"))
const CreateCompanyChoisePersonTypeFlowPage = lazy(() => import("../pages/CreateCompanyChoisePersonTypeFlowPage/CreateCompanyChoisePersonTypeFlowPage"))
const CreatePersonalCompanyFlowPage = lazy(() => import("../pages/CreatePersonalCompanyFlowPage/CreatePersonalCompanyFlowPage"))
const UpdateCompanyBrazilPage = lazy(() => import("../pages/UpdateCompanyBrazilPage/UpdateCompanyBrazilPage"))
const ConfigCompanyPage = lazy(() => import("../pages/ConfigCompanyPage/ConfigCompanyPage"))
const DailyCashFlowPage = lazy(() => import("../pages/DailyCashFlowPage/DailyCashFlowPage"))
const MonthlyCashFlowPage = lazy(() => import("../pages/MonthlyCashFlowPage/MonthlyCashFlowPage"))
const MonthlyAccountingClosingPage = lazy(() => import("../pages/MonthlyAccountingClosingPage/MonthlyAccountingClosingPage"))
const CashFlowReportPage = lazy(() => import("../pages/CashFlowReportPage/CashFlowReportPage"))
const ProductQuotationPage = lazy(() => import("../pages/ProductQuotationPage/ProductQuotationPage"))
const CreateProductQuotationPage = lazy(() => import("../pages/CreateProductQuotationPage/CreateProductQuotationPage"))
const UpdateProductQuotationPage = lazy(() => import("../pages/UpdateProductQuotationPage/UpdateProductQuotationPage"))
const ViewProductQuotationPage = lazy(() => import("../pages/ViewProductQuotationPage/ViewProductQuotationPage"))
const ProductStockEntryPage = lazy(() => import("../pages/ProductStockEntryPage/ProductStockEntryPage"))
const CreateProductStockEntryManualPage = lazy(() => import("../pages/CreateProductStockEntryManualPage/CreateProductStockEntryManualPage"))
const UpdateProductStockEntryPage = lazy(() => import("../pages/UpdateProductStockEntryPage/UpdateProductStockEntryPage"))
const CollaboratorAccountPage = lazy(() => import("../pages/CollaboratorAccountPage/CollaboratorAccountPage"))
const CreateCollaboratorAccountPage = lazy(() => import("../pages/CreateCollaboratorAccountPage/CreateCollaboratorAccountPage"))
const UpdateCollaboratorAccountPage = lazy(() => import("../pages/UpdateCollaboratorAccountPage/UpdateCollaboratorAccountPage"))
const ConfigPosSettingPage = lazy(() => import("../pages/ConfigPosSettingPage/ConfigPosSettingPage"))
const ConfigPosCollaboratorAccountPermissionPage = lazy(() => import("../pages/ConfigPosCollaboratorAccountPermissionPage/ConfigPosCollaboratorAccountPermissionPage"))
const ProductStockReportPage = lazy(() => import("../pages/ProductStockReportPage/ProductStockReportPage"))
const GeneralProductStockReportPage = lazy(() => import("../pages/GeneralProductStockReportPage/GeneralProductStockReportPage"))
const CollaboratorSaleReportPage = lazy(() => import("../pages/CollaboratorSaleReportPage/CollaboratorSaleReportPage"))
const MonthlyCollaboratorSaleReportPage = lazy(() => import("../pages/MonthlyCollaboratorSaleReportPage/MonthlyCollaboratorSaleReportPage"))
const CashFlowPaymentMethodReportPage = lazy(() => import("../pages/CashFlowPaymentMethodReportPage/CashFlowPaymentMethodReportPage"))
const GeneralCollaboratorSaleReportPage = lazy(() => import("../pages/GeneralCollaboratorSaleReportPage/GeneralCollaboratorSaleReportPage"))
const GeneralProductStockEntryReportPage = lazy(() => import("../pages/GeneralProductStockEntryReportPage/GeneralProductStockEntryReportPage"))
const CustomerSaleReportPage = lazy(() => import("../pages/CustomerSaleReportPage/CustomerSaleReportPage"))
const GeneralCustomerSaleReportPage = lazy(() => import("../pages/GeneralCustomerSaleReportPage/GeneralCustomerSaleReportPage"))
const RestaurantSettingPage = lazy(() => import("../pages/restaurant/RestaurantSettingPage/RestaurantSettingPage"))
const CreateRestaurantTablePage = lazy(() => import("../pages/restaurant/CreateRestaurantTablePage/CreateRestaurantTablePage"))
const CreateRestaurantCommandPage = lazy(() => import("../pages/restaurant/CreateRestaurantCommandPage/CreateRestaurantCommandPage"))
const RestaurantPrinterPage = lazy(() => import("../pages/restaurant/RestaurantPrinterPage/RestaurantPrinterPage"))
const RestaurantKdsPage = lazy(() => import("../pages/restaurant/RestaurantKdsPage/RestaurantKdsPage"))
const RestaurantProductPage = lazy(() => import("../pages/restaurant/RestaurantProductPage/RestaurantProductPage"))
const PosCashRegisterPage = lazy(() => import("../pages/PosCashRegisterPage/PosCashRegisterPage"))
const ViewPosCashRegisterPage = lazy(() => import("../pages/ViewPosCashRegisterPage/ViewPosCashRegisterPage"))
const ProductSalesByPosCashRegisterPage = lazy(() => import("../pages/ProductSalesByPosCashRegisterPage/ProductSalesByPosCashRegisterPage"))
const GeneralRestaurantCollaboratorCommissionReportPage = lazy(() => import("../pages/restaurant/GeneralRestaurantCollaboratorCommissionReportPage/GeneralRestaurantCollaboratorCommissionReportPage"))
const GeneralRestaurantItemsCanceledReportPage = lazy(() => import("../pages/restaurant/GeneralRestaurantItemsCanceledReportPage/GeneralRestaurantItemsCanceledReportPage"))
const PosCashRegisterReportPage = lazy(() => import("../pages/PosCashRegisterReportPage/PosCashRegisterReportPage"))
const DailyPosCashRegisterReportPage = lazy(() => import("../pages/DailyPosCashRegisterReportPage/DailyPosCashRegisterReportPage"))
const MonthlyPosCashRegisterReportPage = lazy(() => import("../pages/MonthlyPosCashRegisterReportPage/MonthlyPosCashRegisterReportPage"))
const PosCashRegisterPaymentMethodReportPage = lazy(() => import("../pages/PosCashRegisterPaymentMethodReportPage/PosCashRegisterPaymentMethodReportPage"))
const RestaurantOnlineOrderSettingPage = lazy(() => import("../pages/restaurantOnlineOrder/RestaurantOnlineOrderSettingPage/RestaurantOnlineOrderSettingPage"))
const RestaurantOnlineOrderAttendanceUnitPage = lazy(() => import("../pages/restaurantOnlineOrder/RestaurantOnlineOrderAttendanceUnitPage/RestaurantOnlineOrderAttendanceUnitPage"))
const RestaurantOnlineOrderProductPage = lazy(() => import("../pages/restaurantOnlineOrder/RestaurantOnlineOrderProductPage/RestaurantOnlineOrderProductPage"))
const GeneralRestaurantSaleItemsGroupedByHourReportPage = lazy(() => import("../pages/restaurant/GeneralRestaurantSaleItemsGroupedByHourReportPage/GeneralRestaurantSaleItemsGroupedByHourReportPage"))

const Routes = () => (
    <Suspense fallback={<div>Carregando...</div>}>
        <Switch>
            <Route exact path="/flow/createAccountPlan" component={CreateAccountPlanFlowPage} />
            <Route exact path="/flow/createCompanyChoisePersonType" component={CreateCompanyChoisePersonTypeFlowPage} />
            <Route exact path="/flow/createCompany" component={CreateCompanyFlowPage} />
            <Route exact path="/flow/createPersonalCompany" component={CreatePersonalCompanyFlowPage} />
            <Route exact path="/home" component={HomePage} />
            <Route exact path="/configNFCeCSC" component={ConfigNFCeCSCPage} />
            <Route exact path="/configNFCeSerial" component={ConfigNFCeSerialPage} />
            <Route exact path="/configNFeSerial" component={ConfigNFeSerialPage} />
            <Route exact path="/configNFSeSerial" component={ConfigNFSeSerialPage} />
            <Route exact path="/digitalCertificate" component={DigitalCertificatePage} />
            <Route exact path="/costCenter" component={CostCenterPage} />
            <Route exact path="/productCategory" component={ProductCategoryPage} />
            <Route exact path="/serviceCategory" component={ServiceCategoryPage} />
            <Route exact path="/invoicePayableCategory" component={InvoicePayableCategoryPage} />
            <Route exact path="/invoicePayable" component={InvoicePayablePage} />
            <Route exact path="/service" component={ServicePage} />
            <Route exact path="/createService" component={CreateServicePage} />
            <Route exact path="/updateService/:serviceID" component={UpdateServicePage} />
            <Route exact path="/customers" component={CustomerPage} />
            <Route exact path="/createCustomer" component={CreateCustomerPage} />
            <Route exact path="/updateCustomer/:customerID" component={UpdateCustomerPage} />
            <Route exact path="/serviceSale" component={ServiceSalePage} />
            <Route exact path="/contract" component={ContractPage} />
            <Route exact path="/createContract" component={CreateContractPage} />
            <Route exact path="/updateContract/:contractID" component={UpdateContractPage} />
            <Route exact path="/createServiceSale" component={CreateServiceSalePage} />
            <Route exact path="/invoiceReceivableCategory" component={InvoiceReceivableCategoryPage} />
            <Route exact path="/invoiceReceivable" component={InvoiceReceivablePage} />
            <Route exact path="/supplier" component={SupplierPage} />
            <Route exact path="/createSupplier" component={CreateSupplierPage} />
            <Route exact path="/updateSupplier/:supplierID" component={UpdateSupplierPage} />
            <Route exact path="/transport" component={TransportPage} />
            <Route exact path="/createTransport" component={CreateTransportPage} />
            <Route exact path="/updateTransport/:transportID" component={UpdateTransportPage} />
            <Route exact path="/viewServiceSale/:serviceSaleID" component={ViewServiceSalePage} />
            <Route exact path="/updateServiceSale/:serviceSaleID" component={UpdateServiceSalePage} />
            <Route exact path="/product" component={ProductPage} />
            <Route exact path="/createProduct" component={CreateProductPage} />
            <Route exact path="/updateProduct/:productID" component={UpdateProductPage} />
            <Route exact path="/productSale" component={ProductSalePage} />
            <Route exact path="/createProductSale" component={CreateProductSalePage} />
            <Route exact path="/updateProductSale/:productSaleID" component={UpdateProductSalePage} />
            <Route exact path="/viewProductSale/:productSaleID" component={ViewProductSalePage} />
            <Route exact path="/verifyIssueProductTaxInvoice/:productSaleID" component={VerifyIssueProductTaxInvoicePage} />
            <Route exact path="/issueNFCe/:productSaleID" component={IssueNFCePage} />
            <Route exact path="/checkNFCeEmission/:productSaleID" component={CheckNFCeEmissionPage} />
            <Route exact path="/taxConfig" component={TaxConfigPage} />
            <Route exact path="/accountPlanFreeExpired" component={AccountPlanFreeExpiredPage} />
            <Route exact path="/accountPlanBlockedOverdueInvoice" component={AccountPlanBlockedOverdueInvoicePage} />
            <Route exact path="/accountPlanBlocked" component={AccountPlanBlockedPage} />
            <Route exact path="/updateCompany" component={UpdateCompanyBrazilPage} />
            <Route exact path="/configCompany" component={ConfigCompanyPage} />
            <Route exact path="/dailyCashFlow" component={DailyCashFlowPage} />
            <Route exact path="/monthlyCashFlow" component={MonthlyCashFlowPage} />
            <Route exact path="/monthlyAccountingClosing" component={MonthlyAccountingClosingPage} />
            <Route exact path="/cashFlowReport" component={CashFlowReportPage} />
            <Route exact path="/productQuotation" component={ProductQuotationPage} />
            <Route exact path="/createProductQuotation" component={CreateProductQuotationPage} />
            <Route exact path="/updateProductQuotation/:productQuotationID" component={UpdateProductQuotationPage} />
            <Route exact path="/viewProductQuotation/:productQuotationID" component={ViewProductQuotationPage} />
            <Route exact path="/productStockEntry" component={ProductStockEntryPage} />
            <Route exact path="/createProductStockEntryManual" component={CreateProductStockEntryManualPage} />
            <Route exact path="/updateProductStockEntry/:productStockEntryID" component={UpdateProductStockEntryPage} />
            <Route exact path="/collaboratorAccount" component={CollaboratorAccountPage} />
            <Route exact path="/createCollaboratorAccount" component={CreateCollaboratorAccountPage} />
            <Route exact path="/updateCollaboratorAccount/:collaboratorAccountID" component={UpdateCollaboratorAccountPage} />
            <Route exact path="/configPosSetting" component={ConfigPosSettingPage} />
            <Route exact path="/configPosCollaboratorAccountPermission/:collaboratorAccountID" component={ConfigPosCollaboratorAccountPermissionPage} />
            <Route exact path="/productStockReport" component={ProductStockReportPage} />
            <Route exact path="/generalProductStockReport" component={GeneralProductStockReportPage} />
            <Route exact path="/collaboratorSaleReport" component={CollaboratorSaleReportPage} />
            <Route exact path="/monthlyCollaboratorSaleReport" component={MonthlyCollaboratorSaleReportPage} />
            <Route exact path="/cashFlowPaymentMethodReport" component={CashFlowPaymentMethodReportPage} />
            <Route exact path="/generalCollaboratorSaleReport" component={GeneralCollaboratorSaleReportPage} />
            <Route exact path="/generalProductStockEntryReport" component={GeneralProductStockEntryReportPage} />
            <Route exact path="/generalCustomerSaleReport" component={GeneralCustomerSaleReportPage} />
            <Route exact path="/customerSaleReport" component={CustomerSaleReportPage} />
            <Route exact path="/restaurant/restaurantSetting" component={RestaurantSettingPage} />
            <Route exact path="/restaurant/createRestaurantTable" component={CreateRestaurantTablePage} />
            <Route exact path="/restaurant/createRestaurantCommand" component={CreateRestaurantCommandPage} />
            <Route exact path="/restaurant/restaurantPrinters" component={RestaurantPrinterPage} />
            <Route exact path="/restaurant/restaurantKdss" component={RestaurantKdsPage} />
            <Route exact path="/restaurant/restaurantProducts" component={RestaurantProductPage} />
            <Route exact path="/restaurant/generalRestaurantCollaboratorCommissionsReport" component={GeneralRestaurantCollaboratorCommissionReportPage} />
            <Route exact path="/restaurant/generalRestaurantItemsCanceledReport" component={GeneralRestaurantItemsCanceledReportPage} />
            <Route exact path="/restaurant/generalRestaurantSaleItemsGroupedByHourReport" component={GeneralRestaurantSaleItemsGroupedByHourReportPage} />
            <Route exact path="/restaurantOnlineOrder/restaurantOnlineOrderSetting" component={RestaurantOnlineOrderSettingPage} />
            <Route exact path="/restaurantOnlineOrder/restaurantOnlineOrderAttendanceUnits" component={RestaurantOnlineOrderAttendanceUnitPage} />
            <Route exact path="/restaurantOnlineOrder/restaurantOnlineOrderProducts" component={RestaurantOnlineOrderProductPage} />
            <Route exact path="/posCashRegisters" component={PosCashRegisterPage} />
            <Route exact path="/viewPosCashRegister/:posCashRegisterID" component={ViewPosCashRegisterPage} />
            <Route exact path="/productSalesByPosCashRegister/:posCashRegisterID" component={ProductSalesByPosCashRegisterPage} />
            <Route exact path="/posCashRegisterReport" component={PosCashRegisterReportPage} />
            <Route exact path="/dailyPosCashRegisterReport" component={DailyPosCashRegisterReportPage} />
            <Route exact path="/monthlyPosCashRegisterReport" component={MonthlyPosCashRegisterReportPage} />
            <Route exact path="/posCashRegisterPaymentMethodReport" component={PosCashRegisterPaymentMethodReportPage} />
            <Route exact path="*" component={NoMatchPage} />
        </Switch>
    </Suspense>
)

export { Routes }