import React from 'react'

const NavBarFlow = () => (
    <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
            <a className="navbar-item is-hidden-desktop-only" href="https://twitter.com/b3bnb">
                <span className="icon">
                    <i className="fab fa-linkedin"></i>
                </span>
            </a>
            <a className="navbar-item is-hidden-desktop-only" href="https://twitter.com/b3bnb">
                <span className="icon" style={{ color: '#55acee' }}>
                    <i className="fab fa-twitter"></i>
                </span>
            </a>
            <a className="navbar-item is-hidden-desktop-only" href="https://twitter.com/b3bnb">
                <span className="icon" style={{ color: '#3b5998' }}>
                    <i className="fab fa-facebook"></i>
                </span>
            </a>
            <a className="navbar-item is-hidden-desktop-only" href="https://twitter.com/b3bnb">
                <span className="icon" style={{ color: 'red' }}>
                    <i className="fab fa-youtube"></i>
                </span>
            </a>
        </div>
    </nav>
)

export {
    NavBarFlow
}