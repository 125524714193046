const initialState = {
    history: null,
    token: null,
    account: null,
    accountPlan: null,
    company: null,
    isLogged: false,
    isLoad: false,
}

export const Types = {
    TOKEN_ADD: 'session/TOKEN_ADD',
    HISTORY_ADD: 'session/HISTORY_ADD',
    LOGIN: 'session/LOGIN',
    LOGOUT: 'session/LOGOUT',
    ACCOUNT_ADD: 'session/ACCOUNT_ADD',
    ACCOUNT_PLAN_ADD: 'session/ACCOUNT_PLAN_ADD',
    COMPANY_ADD: 'session/COMPANY_ADD',
    ENABLE_LOAD: 'session/ENABLE_LOAD',
    DISABLE_LOAD: 'session/DISABLE_LOAD',
}

export function sessionTokenAdd(token) {
    return {
        type: Types.TOKEN_ADD,
        payload: {
            token
        }
    }
}

export function sessionHistoryAdd(history) {
    return {
        type: Types.HISTORY_ADD,
        payload: {
            history
        }
    }
}

export function sessionLogin(token, account, accountPlan, company) {
    return {
        type: Types.LOGIN,
        payload: {
            token,
            account,
            accountPlan,
            company
        }
    }
}

export function sessionAccountAdd(account) {
    return {
        type: Types.ACCOUNT_ADD,
        payload: {
            account,
        }
    }
}

export function sessionAccountPlanAdd(accountPlan) {
    return {
        type: Types.ACCOUNT_PLAN_ADD,
        payload: {
            accountPlan,
        }
    }
}

export function companyAdd(state, company) {
    return {
        type: Types.COMPANY_ADD,
        payload: {
            state,
            company
        }
    }
}

export function logout() {
    return {
        type: Types.LOGOUT
    }
}

export function enableLoad() {
    return {
        type: Types.ENABLE_LOAD,
        payload: {
            isLoad: true,
        }
    }
}

export function disableLoad() {
    return {
        type: Types.DISABLE_LOAD,
        payload: {
            isLoad: false,
        }
    }
}

export default function reducer(state = initialState, action) {
    switch (action.type) {
        case Types.TOKEN_ADD:
            return {
                ...state,
                token: action.payload.token
            }
        case Types.HISTORY_ADD:
            return {
                ...state,
                history: action.payload.history
            }
        case Types.LOGIN:
            return {
                ...state,
                account: action.payload.account,
                accountPlan: action.payload.accountPlan,
                company: action.payload.company,
                isLogged: true
            }
        case Types.ACCOUNT_ADD:
            return {
                ...state,
                account: action.payload.account
            }
        case Types.ACCOUNT_PLAN_ADD:
            return {
                ...state,
                accountPlan: action.payload.accountPlan
            }
        case Types.COMPANY_ADD:
            return initialState
        case Types.LOGOUT:
            return initialState
        case Types.ENABLE_LOAD:
            return {
                ...state,
                isLoad: action.payload.isLoad
            }
        case Types.DISABLE_LOAD:
            return {
                ...state,
                isLoad: action.payload.isLoad
            }
        default:
            return state
    }
}