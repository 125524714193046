import { getToken } from '../../../../domain/apis/security_cookie'
import { graphqlB3bnbAccountApiV1, graphqlB3bnbAccountPlanApiV1, graphqlB3bnbCompanyBrazilApiV1 } from '../../../../domain/apis/graphql'
import { accountByTokenQuery, accountPlanInboxBusinessBrazilByAccountQuery, companiesBrazilQuery } from './graphql/query'

async function getCookieToken() {
    return await getToken()
}

async function getAccountByToken(token) {
    const payload = {
        token
    }

    return await graphqlB3bnbAccountApiV1(accountByTokenQuery, payload)
}

async function geAccountPlanInboxBusinessBrazilByAccount(accountID) {
    const payload = {
        accountID
    }

    return await graphqlB3bnbAccountPlanApiV1(accountPlanInboxBusinessBrazilByAccountQuery, payload)
}

async function listCompaniesBrazil(accountID) {
    const payload = {
        accountID
    }

    return await graphqlB3bnbCompanyBrazilApiV1(companiesBrazilQuery, payload)
}

const Controller = {
    getCookieToken,
    getAccountByToken,
    geAccountPlanInboxBusinessBrazilByAccount,
    listCompaniesBrazil
}

export {
    Controller
}