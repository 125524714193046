import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Controller } from './controller/controller'
import { getError } from '../../../domain/apis/graphql'
import { NavBarRender } from './NavBarRender'
import './style.css'

function NavBar() {
    const isLogged = useSelector(state => state.session.isLogged)
    const [planStatusID, setPlanStatusID] = useState("")
    const accountSession = useSelector(state => state.session.account)
    const sessionAccountPlan = useSelector(state => state.session.accountPlan)
    const companySession = useSelector(state => state.session.company)
    const [listMenus, setListMenus] = useState([])

    useEffect(() => {
        verifyListMenus()
    }, [isLogged])

    useEffect(() => {
        verifyListMenus()
    }, [sessionAccountPlan])

    async function verifyListMenus() {
        if (isLogged) {
            setPlanStatusID(sessionAccountPlan.planStatusID)

            const listMenusResponse = await Controller.listMenus(accountSession.ID, sessionAccountPlan.ID, companySession.ID)
            const errorListMenusResponse = getError(listMenusResponse)

            if (!errorListMenusResponse) {
                setListMenus(listMenusResponse.data.accountFunctionalitiesMenuPermission.menus)
            }
        } else {
            setListMenus([])
        }
    }

    return (
        <NavBarRender
            isLogged={isLogged}
            planStatusID={planStatusID}
            listMenus={listMenus} />
    )
}

export { NavBar }