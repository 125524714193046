import React from "react"
import "./style.css"

function getFirstWord(name) {
    if (!name || name.length === 0) {
        return "A"
    }

    return name.charAt(0)
}

const AvatarWord = ({
    name }) => (
    <div className="avatar-word">
        {getFirstWord(name)}
    </div>
)

export {
    AvatarWord
}