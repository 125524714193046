import React from 'react'
import { AvatarRender } from "./AvatarRender"

function Avatar({
    name }) {
    return (
        <AvatarRender
            name={name} />
    )
}

export {
    Avatar
}