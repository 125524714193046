import React from 'react'
import { useSelector } from 'react-redux';
import { modal } from '../../commons/modal';
import { Avatar } from '../Avatar/Avatar';
import { MenuBurger } from './MenuBurger';
import { MenuDesktop } from './MenuDesktop'

function NavBarLogged({ listMenus }) {
    const accountSession = useSelector(state => state.session.account)

    function openMenuBurger() {
        modal.open("modalMenuBurgerBig")
    }

    return (
        <nav className="navbar is-transparent" role="navigation" aria-label="dropdown navigation">
            <div className="navbar-brand">
                <a className="navbar-item">
                    <div style={{ fontSize: "18px", backgroundColor: "#265D26", padding: "2px 5px", color: "#fff" }}>B3BNB</div>
                    <div style={{ marginLeft: "16px", padding: "5px", color: "#000" }}>|</div>
                    <div style={{ marginLeft: "5px", padding: "5px", color: "#000" }}>Inbox</div>
                </a>
                <div className="buttonMenuBurger" data-target="navbarExampleTransparentExample" style={{ marginRight: "6px", marginLeft: "auto" }}>
                    <button className="button" style={{ borderRadius: "10px", marginTop: "9px", fontSize: "0.8rem" }} onClick={_ => openMenuBurger()}>
                        {/* <span>{accountSession.name}</span> */}
                        <span className="icon">
                            {/* <i className="fas fa-bars"></i> */}
                            <img src="/grid-2.png" style={{ width: "20px" }} />
                        </span>
                    </button>
                </div>
            </div>
            <MenuDesktop
                listMenus={listMenus} />
            <MenuBurger />
        </nav>
    )
}

export {
    NavBarLogged
}