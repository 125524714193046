import { graphqlInboxBusinessSecurityBrazilApiV1 } from '../../../../domain/apis/graphql'
import { accountFunctionalitiesMenuPermissionQuery } from './graphql/query'

async function listMenus(accountID, accountPlanID, companyID) {
    const payload = {
        accountID,
        accountPlanID,
        companyID
    }

    return await graphqlInboxBusinessSecurityBrazilApiV1(accountFunctionalitiesMenuPermissionQuery, payload)
}

const Controller = {
    listMenus
}

export {
    Controller
}