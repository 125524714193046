import React from 'react'
import { NavBarLogged } from './NavBarLogged'
import { NavBarFlow } from './NavBarFlow'
import { NavBarAccountPlanFreeExpired } from './NavBarAccountPlanFreeExpired'
import { NavBarAccountPlanBlockedOverdueInvoice } from './NavBarAccountPlanBlockedOverdueInvoice'
import { NavBarAccountPlanBlocked } from './NavBarAccountPlanBlocked'
import {
    PLAN_STATUS_ACTIVE, PLAN_STATUS_ACTIVE_FREE,
    PLAN_STATUS_FREE_EXPIRED, PLAN_STATUS_BLOCKED,
    PLAN_STATUS_BLOCKED_OVERDUE_INVOICE
} from '../../../domain/constants/plan_status'

const NavBarRender = ({ isLogged, planStatusID, listMenus }) => {
    if (isLogged === true) {
        if (planStatusID === PLAN_STATUS_ACTIVE || planStatusID === PLAN_STATUS_ACTIVE_FREE) {
            return (<NavBarLogged
                listMenus={listMenus} />)
        } else if (planStatusID === PLAN_STATUS_FREE_EXPIRED) {
            return (<NavBarAccountPlanFreeExpired />)
        } else if (planStatusID === PLAN_STATUS_BLOCKED_OVERDUE_INVOICE) {
            return (<NavBarAccountPlanBlockedOverdueInvoice />)
        } else if (planStatusID === PLAN_STATUS_BLOCKED) {
            return (<NavBarAccountPlanBlocked />)
        } else {
            return (
                <></>
            )
        }
    } else {
        return (<NavBarFlow />)
    }
}

export {
    NavBarRender
}