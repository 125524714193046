const open = (nameModal) => {
    var element, name, arr
    element = document.getElementById(nameModal)
    name = 'is-active'
    arr = element.className.split(' ')
    if (arr.indexOf(name) === -1) {
        element.className += ' ' + name
    }
}

const close = (nameModal) => {
    var element = document.getElementById(nameModal)
    element.className = element.className.replace(/\bis-active\b/g, "")
}

const modal = {
    open,
    close
}

export {
    modal
}