const accountByTokenQuery = `
query($token:String!){
    accountByToken(token:$token){
        account{
            ID,
            name,
            email
        },
        error{
            code,
            message
        }
    }
}
`

const accountPlanInboxBusinessBrazilByAccountQuery = `
query($accountID:ID!){
    accountPlanInboxBusinessBrazilByAccount(accountID:$accountID){
        accountPlan{
            ID,
            planStatusID,
            accountPlanInvoiceID
        },
        error{
            code,
            message
        }
    }
}
`

const companiesBrazilQuery = `
query($accountID:ID!){
    companiesBrazil(accountID:$accountID){
        companiesBrazil{
            ID,
            document,
            socialName
        },
        error{
            code,
            message
        }
    }
}
`

export {
    accountByTokenQuery,
    accountPlanInboxBusinessBrazilByAccountQuery,
    companiesBrazilQuery
}